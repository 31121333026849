<template>
  <SfImage
    v-if="urlLogoBrand"
    class="m-brand-menu-list__image"
    :alt="name"
    :src="urlLogoBrand"
  />
</template>
<script>
import Fetch from '@vue-storefront/core/lib/isomorphic-fetch'
import { getBrandImagePath } from 'theme/helpers'
import { SfImage } from '@storefront-ui/vue'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'MRenderImageMenu',
  components: {
    SfImage
  },
  props: {
    brandId: String,
    name: String
  },
  data () {
    return {
      urlLogoBrand: null
    }
  },
  async created () {
    if (isServer) return

    const res = await this.getImageOrFallback(this.brandId)
    const parser = new DOMParser();

    if (res.status === 200) {
      const doc = parser.parseFromString(await res.text(), 'image/svg+xml');
      const getDoc = doc.querySelector('image')

      if (getDoc) {
        const getLink = getDoc.getAttribute('xlink:href')
        this.urlLogoBrand = getLink
      } else {
        this.urlLogoBrand = res.url
      }
    } else {
      this.urlLogoBrand = null
    }
  },
  methods: {
    getImageOrFallback (id) {
      const getUrl = getBrandImagePath(id)

      return Fetch(getUrl)
    }
  }
}
</script>
